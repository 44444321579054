


































import { Cliente, Funcionario } from "@/core/models/geral";
import { Embarcacao } from "@/core/models/operacional";
import { PageBase } from "@/core/models/shared";
import { ClienteService, FuncionarioService } from "@/core/services/geral";
import { EmbarcacaoService } from "@/core/services/operacional";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ConfiguracaoAtracacaoROA extends PageBase{
    
    filtro: any = {
        roNumero: null,
        funcionarioId: null,
        clienteId: null,
        embarcacaoId: null
    }

    funcionarios: Funcionario[] = [];
    funcionarioService: FuncionarioService = new FuncionarioService();

    clientes: Cliente[] = [];
    clienteService: ClienteService = new ClienteService();

    embarcacoes: Embarcacao[] = [];
    embarcacaoService: EmbarcacaoService = new EmbarcacaoService();

    mounted() {
        this.Carregar();
    }

    Carregar(){
        this.funcionarioService.ListarTudo().then(
            res => {
                let funci = res.data.items;
                var teste = funci.find(x => x.usuarioId == this.app.usuarioId);
                if(teste){
                    this.funcionarios = [];
                    this.funcionarios.push(teste);
                }else{
                    this.funcionarios = res.data.items;
                }                
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.clienteService.ListarTudo().then(
            res => {
                this.clientes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.embarcacaoService.ListarTudo().then(
            res => {
                this.embarcacoes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }
    
    Detalhar(){        
        let routeLink = this.$router.resolve({ name: 'relatorioAgendaFuncionario',
            query:{
                roNumero: this.filtro.roNumero,
                funcionarioId: this.filtro.funcionarioId,
                clienteId: this.filtro.clienteId,
                embarcacaoId: this.filtro.embarcacaoId
            }
        });
        window.open(routeLink.href, '_blank');
    }

}
